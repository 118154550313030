import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import { RichTextElement } from "@kentico/gatsby-kontent-components"

class ContactRoute extends React.Component {
    render() {
        const contactPageData = this.props.data
        const postfix = contactPageData.kontentItemSiteMetadata.elements.title.value
        const kontentItemContactPage = contactPageData.kontentItemContactPage
        const elements = kontentItemContactPage.elements
        const pageTitle = elements.title.value
        const pageSubtitle = elements.subtitle.value
        const metaDescription = elements.meta_description.value
        const shortDescription = elements.short_description.value
        const contactsTitle = elements.contacts_title.value
        const name = elements.name.value
        var email = elements.email.value
        var emailTo = "mailto:" + email
        var phone = elements.phone.value
        var callTo = "tel:" + phone
        const contactDescription = elements.contact_description.value
        const nameSec = elements.name_secondary.value
        var emailSec = elements.email_secondary.value
        var emailToSec = "mailto:" + emailSec
        var phoneSec = elements.phone_secondary.value
        var callToSec = "tel:" + phoneSec
        const contactDescriptionSec = elements.contact_description_secondary.value
        const billingInfoTitle = elements.billing_info_title.value
        const billingInfo = elements.billing_info.value

        return (
            <Layout>
                <div>
                    <Meta title={pageTitle} postfix={postfix} description={metaDescription} />
                    <Header isContactPage />
                    <PageHeading data={pageSubtitle} />

                    <div className="section container"><div className="row">
                        <div className="col-sm-10">
                            <div className="section__text-wo-title">
                                <RichTextElement
                                    value={shortDescription} />
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-sm-10 col-md-10">
                                <h2 className="section__title--sub">{contactsTitle}</h2>
                                <div className="contact">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h3 className="contact__name">{name}</h3>
                                            <span className="contact__phone">Tel: <a href={callTo}>{phone}</a></span>
                                            <span className="contact__email">E-mail: <a href={emailTo}>{email}</a></span>
                                        </div>
                                        <div className="col-sm-6">
                                            <p className="contact__description">{contactDescription}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h3 className="contact__name">{nameSec}</h3>
                                            <span className="contact__phone">Tel: <a href={callToSec}>{phoneSec}</a></span>
                                            <span className="contact__mail">E-mail: <a href={emailToSec}>{emailToSec}</a></span>
                                        </div>
                                        <div className="col-sm-6">
                                            <p className="contact__description">{contactDescriptionSec}</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="section__title--sub">{billingInfoTitle}</h2>
                                <div className="address">
                                    <RichTextElement
                                        value={billingInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer isContactPage />
                </div>
            </Layout>
        )
    }
}

export default ContactRoute

export const pageQuery = graphql`
query ContactQuery($codename: String!) {
  kontentItemSiteMetadata(system: { codename: { eq: "site_metadata" } }) {
    elements {
      title {
        value
      }
    }
  }
  kontentItemContactPage(system: { codename: { eq: $codename } }) {
    id
    system {
      id
    }
    elements {
      title {
        value
      }
      subtitle {
        value
      }
      meta_description {
        value
      }
      short_description {
        value
      }
      contacts_title {
        value
      }
      name {
        value
      }
      email {
        value
      }
      phone {
        value
      }
      contact_description {
        value
      }
      name_secondary {
        value
      }
      email_secondary {
        value
      }
      phone_secondary {
        value
      }
      contact_description_secondary {
        value
      }
      billing_info_title {
        value
      }
      billing_info {
        value
      }
    }
  }
}
`