import React from 'react'

class Hero extends React.Component {

    render() {
        const pageTitle = this.props.data

        return (
            <div>
                <header className="container--full header header--other header-js parallax-js">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxs-24">
                                <div className="header__heading">
                                    <h1>{pageTitle}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default Hero
